import React, { useState } from "react";
import { Container, Row, Col, Button, Nav, Modal } from "react-bootstrap";
import artficialReefs from "../images/artificial-reefs.jpg";
import marineEcosystems from "../images/marine-ecosystems.jpg";
import estuaryOysterReef from "../images/estuary.jpg";
import waveAttenuator from "../images/wave-attenuator-reefmaker.jpg";
import waveAttenuatorVideo from "../images/deadman-island.mp4";

function MarineReefsModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Marine EcoSystems
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>More about Marine EcoSystems</h4>
        <p className="lead">
          When we first started developing the EcoSystems it was just another
          reef. However; over time we expanded them into: wave attenuation,
          snorkeling reefs, oyster reef restoration, mitigation, personal dock
          reef, coral reef restoration, and is also used as a research tool. The
          design allows small marine animals to seek shelter and protection from
          larger predators. They are ideal for defusing wave energy and
          protecting shorelines. EcoSystems can be placed in high-wave energy
          areas without concern for storm event damage. The Florida Limestone
          rock we use replicates natural reef substrate and PH levels. The
          piling mount system allows the unit to remain upright and not sink
          into the bottom. EcoSystems can be configured for any depth, shape,
          size, or complexity.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
const lightSide = {
  background: "rgb(231, 246, 242)",
  color: "#2D4059",
};

const modalStyle = {
  background: "#2D4059",
  color: "rgb(231, 246, 242)",
};

const marineEcoSystemReefs = [
  {
    img: estuaryOysterReef,
    title: "Estuary - Oyster Reef",
    price: "Contact Us",
    description:
      "Quote from Estuarine Coastal and Shelf Science, Survival and growth of early fish stages are maximal in coastal and estuarine habitats where natural shallow areas serve as nurseries for a variety of widely distributed species on the continental shelf. Processes occurring in these nursery grounds during the juvenile stage affect growth and may be important in regulating the year-class strength of fishes and population size. The need, therefore, exists to protect these essential fish habitats. Bulkheads, dredging, trawling, hurricanes, pollution, and climate changes are destroying estuarine habitat at an alarming rate. EcoSystems can provide a substitute",
  },
  {
    img: artficialReefs,
    title: "Snorkeling Reef",
    price: "Contact Us",
    description:
      "has a complex design that allows small marine animals to seek shelter and protection from larger predators. This reef mounted on a piling can withstand high-wave energy in the surf without damage from storm events. The Florida Limestone rock we use replicates natural reef substrate and PH levels. The EcoSystems create an environment for all the marine life including boring animals. The piling mount system allows the unit to remain upright and not sink into the bottom. EcoSystems can be configured for any depth, shape, size or complexity.",
  },
  {
    img: waveAttenuator,
    title: "Wave Attenuator",
    price: "Contact Us",
    description:
      "EcoSystems can be utilized as a Living Wave Barrier to protect shoreline erosion. Before EcoSystems shoreline protection was only made possible with hard armoring bulkheads or rip-rap. There are other types of wave attenuators that have been tested however have failed for two reasons. One is that they sink into the ocean floor and the other is they stop the natural ebb and flow of tides and currents. Walter Marine's EcoSystems are in use at the historic Deadman's Island, Gulf Breeze, FL., East Port Yacht Club in Annapolis, MD, and Yellow River Aquatic Preserve in Milton, FL. The EcoSystem units are green solutions to shoreline erosion. Their complex design destroys wave energy while allowing water to flow freely through. They have exceeded the U.S. Army Corps of Engineers requirements for permeable flushing while providing habitat for marine life. The piling mount supports the unit and prevents sinking, turning over, and moving during hurricane force winds and waves.",
    video: waveAttenuatorVideo,
  },
];
const MarineFeaturette = () => {
  const [memodalShow, setmeModalShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  return (
    <Container fluid className="py-3" style={lightSide}>
      <Container
        className="my-4 p-4 pb-0 pe-lg-0 align-items-center rounded-3 border shadow-lg"
        style={modalStyle}
        id="marine-ecosystems"
      >
        <Row>
          <Col md={7} className="order-md-2 m-auto">
            <h2 className="fw-bold lh-1">Marine EcoSystems</h2>
            <p className="lead">
              When we first started developing the EcoSystems it was just
              another reef. However; over time we expanded them into: wave
              attenuation, snorkeling reefs, oyster reef restoration,
              mitigation, personal dock reef, coral reef restoration, and is
              also used as a research tool.
            </p>
            <Button
              variant="primary"
              onClick={() => setmeModalShow(true)}
              className="mb-3"
            >
              Read More
            </Button>
            <MarineReefsModal
              show={memodalShow}
              onHide={() => setmeModalShow(false)}
            />
          </Col>
          <Col md={5} className="order-md-1">
            <img
              src={marineEcosystems}
              className="bd-placeholder-img bd-placeholder-img-lg img-fluid rounded-3 mx-auto"
              width={"500"}
              height={"500"}
              alt=""
            />
          </Col>
        </Row>
        <Row className="py-5 justify-content-center text-center">
          {marineEcoSystemReefs.map((link, index) => (
            <Col lg={4} className="pt-4" key={index}>
              <Nav.Link key={index} onClick={() => setLgShow(index)}>
                <img
                  src={link.img}
                  className="bd-placeholder-img rounded"
                  width={"140"}
                  height={"140"}
                  alt=""
                />
                <h3>{link.title}</h3>
              </Nav.Link>
              {link.video ? (
                <Modal
                  size="lg"
                  show={lgShow === index}
                  onHide={() => setLgShow(false)}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{link.title}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row>
                        <Col>
                          {link.price === "Contact Us" ? (
                            <Button href="#contact" onClick={setLgShow}>
                              Contact Us
                            </Button>
                          ) : (
                            <p>Price: {link.price}</p>
                          )}
                          <p className="lead">{link.description}</p>
                          <video width={"100%"} className="rounded" autoPlay>
                            <source src={link.video} type="video/mp4" />
                          </video>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                </Modal>
              ) : (
                <Modal
                  size="lg"
                  show={lgShow === index}
                  onHide={() => setLgShow(false)}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{link.title}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row>
                        <Col xs={12} md={8} key={link.title}>
                          {link.price === "Contact Us" ? (
                            <Button href="#contact" onClick={setLgShow}>
                              Contact Us
                            </Button>
                          ) : (
                            <p>Price: {link.price}</p>
                          )}
                          <p className="lead">{link.description}</p>
                        </Col>
                        <Col xs={6} md={4}>
                          <img
                            src={link.img}
                            className="rounded"
                            width={"240"}
                            alt=""
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                </Modal>
              )}
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default MarineFeaturette;
